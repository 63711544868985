:root {
    /*-- Define variables here */
    --color-divider: var(--hs-blue-20);
}

#menu {
    position: fixed;
    display: none; /* active class is added and removed to show and hide menu */
    color: var(--color-text);
    background-color: white;
    border: 1px solid var(--hs-blue-100);
    width: 70vw;
    height: calc(var(--vh, 1vh) * 100);
    text-align: left;
    top: 0;
    right: 0;
    z-index: 1100;
}

#menu.active {
    display: block;
}

#menu .header {
    padding: 1vh 2vw;
}

#menu .banner {
    background-color: var(--color-header);
    color: var(--color-header-text);
    text-align: right;
    padding: 1vh 2vw;
}

#menu .body {
    height: calc(var(--vh, 1vh) * 80);
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 1rem 1rem;
}

#menu .header .close {
    float: right;
    font-size: large;
}

#menu .header img.ansos2go {
    width: 70%;
}

#menu .header .webscheduler {
    font-size: calc(var(--vh, 1vh) * 2.5);
    font-weight: bold;
}

#menu .banner .user, #menu .banner .profile {
    font-size: calc(var(--vh, 1vh) * 2);
    line-height: normal;
}

#menu .body .nav-link {
    display: block;
    text-align: left;
    font-size: calc(var(--vh, 1vh) * 2);
    min-width: 90%;
    max-width: 90%;
    color: var(--color-text);
    margin-left: 3vw;
}

#menu .body .active.nav-link {
    color: var(--color-link-focus);
}

#menu .body .nav-icon {
    font-size: larger;
    width: calc(var(--vh, 1vh) * 2);
    text-align: center;
}

#menu .body .nav-name {
    font-size: calc(var(--vh, 1vh) * 2.5);
    font-weight: bold;
    display: inline;
    margin-left: 5vw;
}

#menu .fa-external-link-alt {
    font-size: calc(var(--vh, 1vh) * 1.5);
    vertical-align: middle;
}

/*#menu a {*/
/*    color: var(--color-text);*/
/*}*/
#menu a:link { text-decoration: none; }
#menu a:visited { text-decoration: none; }
#menu a:hover { text-decoration: none; }
#menu a:active { text-decoration: none; }

#menu div.signout {
    padding: 0 5rem;
    border-top: .1rem solid var(--color-text);
    text-align: center;
}

#menu .signout button.btn.btn-primary {
    color: white;
    background-color: var(--hs-blue-100);
    font-size: calc(var(--vh, 1vh) * 1.5);
    line-height: 1.5;
}

#menu .signout .icon {
    color: white;
    font-size: calc(var(--vh, 1vh) * 1.5);
}

/* Status Indicators */
.hasSwapProposals #menu .recipient-swaps.nav-link {
    color: var(--hs-orange);
}

#menu div.available-shifts-calendar,
#menu div.user-profile,
#menu div.swaps-status,
#menu div.signout {
    padding: var(--vh, 1vh) 0 0 0;
    border-top: .2vh solid var(--color-divider);
}

#menu div.notifications {
    margin-left: 5vw;
}
