#areaScheduleControls {
    background-color: var(--hs-blue-20);
    color: var(--color-text);
    display: flex;
    flex-direction: column;
    border: 0;
    margin: 0;
    padding: 0;
}

#areaScheduleControls .area {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: var(--height-selector);
    background-color: var(--hs-blue-20);
    color: var(--color-text);
    border-bottom: .1rem solid var(--hs-blue-60);
}

#areaScheduleControls .area .form-control {
    background-color: var(--hs-blue-20);
    color: inherit;
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    border: 0;
    border-radius: 0;
    transition: none;
}

#areaScheduleControls .period {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: var(--height-selector);
    background-color: var(--hs-blue-20);
    color: var(--color-text);
    border-bottom: .1rem solid var(--hs-blue-60);
}


#areaScheduleControls .period .form-control {
    background-color: var(--hs-blue-20);
    color: inherit;
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    border: 0;
    border-radius: 0;
    transition: none;
}

#areaScheduleControls .areaScheduleSelect {
    width: 100%
}

#areaScheduleControls .period .form-control:disabled {
    opacity: 50%;
}

#areaScheduleControls .periodNotice {
    border-left: .1rem solid var(--hs-blue-60);
    padding-right: .5rem;
    min-width: 30%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: right;
    font-size: 1.2rem;
    font-weight: bold;
}

#areaScheduleControls .periodNotice:empty {
    display: none;
}
