
.profileCardHeader {
    background-color: var(--hs-blue-80);
    padding: 0.9rem;
}


.toggleButton {
    border: none;
    padding: 0;
    background: none;
    width: 100%;

}

.profileToggle {
    font-size: 1.6rem;
    color: var(--color-header-text);
}

.profileBigLabel {
    color: var(--color-header-text);
    font-size: 1.5rem;
    font-weight: 700;
    padding-top: 0.25rem;
}

.spaceBetween {
    display: flex;
    justify-content: space-between;
}

.userInfo {
    word-break: break-all;
    margin-bottom: 1.5rem;
}


.alertFont {
    font-size: 1.2rem;
    font-style: italic;
}

.hsCheckboxLabel {
    position: relative;
    bottom: 0.3rem;
    font-size: 1.5rem;
    margin-left: 1.8rem;
    color: var(--color-text);
}

.areaLabelSpan {
    margin-left: 1.8rem;
}

.customNameText {
    width: 100%;
    margin-bottom: 1rem;
    font-size: 1.5rem;
}

.floatRight {
    display: flex;
    justify-content: flex-end;
}

.floatLeft {
    display: flex;
    justify-content: flex-start;
}

.boldFont {
    font-weight: 700;
}

.submitDiv {
    padding: 1rem;
}

.formLabel {
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 0.2rem;
    color: var(--color-text);
}

.fieldsetClass {
    border: 0.1rem solid var(--hs-blue-20);
    padding: 0.9rem;
}

.marginBottomDiv {
    margin-bottom: 1.4rem;
}


#changePasswordForm, #changePasswordForm .form-label {
    font-size: 1.2rem;
    color: var(--color-text);
}


#userView, #userView .form-control, #userView .form-label {
    font-size: 1.5rem;
    color: var(--color-text);
}

.form-label {
    margin: 1rem 1rem 1rem 0;
}

#verifyEmailOrPhoneForm {
    text-align: center;
    font-size: 1.2rem;
}

#userView .form-control-plaintext {
    color: var(--color-text);
}


.timeControlDiv {
    background-color: white;
    border: 0.1rem solid var(--hs-blue-20);
    border-radius: 0.3rem;
    height: 3rem;
    padding: 0.25rem 1rem 0.25rem 1rem;
    display: inline-flex;
}

.timeControlIconDiv {
    height: 3rem;
}


.timeControlInput {
    background-color: white;
    border: 0;
    width: 13.5rem;
    color: var(--color-text);
}

.timeControlInput:focus {
    outline: none;
}

/****************** Start of customizing time picker popup style  *******************/
/* Note I have googled a lot hoping to have a better way to do this, but no luck    */
#userView .react-datepicker, #userView .react-datepicker-time__header {
    font-family: 'janeway', sans-serif;
    font-size: 1.4rem;
    color: var(--color-text);
}


#userView .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    height: 3.75rem;
}


#userView .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: var(--hs-blue-80);
}

#userView .react-datepicker__time-container, #userView .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 15rem;
}


#userView .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    height: 21rem;
}

/****************** End of customizing time picker popup style  *******************/


