#requestForm, #avlRequestForm{
    font-size: 1.2rem;
    margin: 0 0 0 0;
    color: var(--color-text);
}

#requestForm .form-group {
    display: flex;
    align-items: center;
}
#requestForm .form-label {
    font-weight: bold;
    text-align: left;
    width: 30%;
    margin: 0 0 0 0;
    vertical-align: middle;

}
#requestForm .form-control {
    text-align: left;
    width: 70%;
}
.areaScheduleSelect{
    width:70%;
}

#requestForm > div > div > div.areaScheduleSelect select  {
    width:100%;
}

.datePickr {
    text-align: left;
    width:70%;
}

.datePickr > div > div.react-datepicker__input-container input {
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    color: #495057;
    border: 1px solid #ced4da;
}

#avlRequestForm .avlBigLabel {
    font-weight: bold;
    font-size: 1.4rem;
}

.alignCenter{
    text-align: center;
}

.avlShiftRadio {
    flex: 0 0 10%;
}


#avlWorkshiftDiv {
    height: 40vh;
    width: 100%;
    overflow-y: scroll;
    -webkit-mask-image: linear-gradient(180deg, var(--color-text) 80%, transparent);
    mask-image: linear-gradient(180deg, var(--color-text) 80%, transparent);
    padding-bottom: 2rem;
}


.avlShiftDetail {
    flex: 0 0 90%;
    position: relative;
    bottom: 0.3rem;
}


.avlShift{
    border-radius: 0.9rem;
    border:  0.1rem solid var(--hs-blue-20);
    padding: 0.4rem;
    margin: 0.4rem 0.4rem 0.9rem 0.4rem;
    font-size:  1.4rem;
    width: 98%;
}


.catCol {
    width: 65%;
}


/*********************    Start of customizing html date input display  *******************/
/** Below would have html native datepicker display the value of attribute "data-date",  **/
/** instead of dictated by browser locale                                                **/

#requestEndDate{
    position: relative;
    color: transparent;
}

#requestEndDate:before {
    position: absolute;
    top: 0.357rem;
    /*left: 1rem;*/
    content: attr(data-date);
    display: inline-block;
    color: dimgray;
}

#requestEndDate::-webkit-datetime-edit, #requestEndDate::-webkit-inner-spin-button, #requestEndDate::-webkit-clear-button {
    display: none;
    color: transparent;
}


#requestEndDate::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0.357rem;
    right: 0;
    opacity: 1;
}
/*********************    End of customizing html date input display  *******************/