:root {
    --height-header: 3rem;
    --height-footer: 4rem;
    --height-selector: 3rem;
}

#layout {
    height: 100%;
}

#layout header {
    width: 100%;
    height: var(--height-header);
    position: fixed;
    top: 0;
    left: 0;
    color: var(--color-header-text);
    background-color: var(--color-header);
}

#layout footer {
    width: 100%;
    height: var(--height-footer);
    position: fixed;
    bottom: 0;
    left: 0;
    display: block;
    z-index: 1000;
}

.body {
    height: 100%;
}
.body.header {
    width: 100%;
    height: calc(100vh - var(--height-header));
    position: fixed;
    top: var(--height-header);
    left: 0;
    overflow: scroll;
    color: var(--color-text);
    background-color: var(--color-background);
}

.body.footer {
    height: calc(100vh - var(--height-footer));
}

.body.header.footer {
    height: calc(100vh - var(--height-header) - var(--height-footer));
    max-height: 90%;
}

header .column-left {
    display: table-cell;
    vertical-align: bottom;
    width: 50vw;
    height: var(--height-header);
    padding-left: 1vw;
    padding-bottom: 1vw;
}

header .column-right {
    display: table-cell;
    vertical-align: bottom;
    text-align: right;
    width: 50vw;
    padding-right: 1vw;
    padding-bottom: 1vw;
    height: var(--height-header);
}
