:root {
    --color-link: var(--hs-blue-100);
    --color-link-focus: var(--hs-primary-stream-blue);
}

#hotbar .navbar {
    padding: 0 0 0 0 !important;
    font-size: 1.5rem;
    font-weight: bolder;
}

.standaloneMode #hotbar .navbar {
    padding: 0 0 env(safe-area-inset-bottom) 0 !important;
}

#hotbar .nav-link {
    text-align: center;
    min-width: 19.5vw;
    max-width: 19.5vw;
    padding: 0 !important;
}

#hotbar .navbar-3 .nav-link {
    min-width: 30vw;
    max-width: 30vw;
}

#hotbar .navbar-4 .nav-link {
    min-width: 23vw;
    max-width: 23vw;
}

#hotbar .navbar a {
    color: var(--color-link);
}

#hotbar .navbar a.active {
    color: var(--color-link-focus);
}

#hotbar .navbar-light .navbar-nav .nav-link {
    color: var(--color-link) !important;
}

#hotbar .nav-name {
    font-size: .8rem;
    /* Make the name a block so it occupies its own line. */
    display: block;
}

/* Status Indicators */
.hasSwapProposals #hotbar .recipient-swaps.nav-link {
    color: var(--hs-orange);
}


