.name-value-list .form-group {
    display: flex;
    align-items: center;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    min-height: 2.5em;
}

.name-value-list.striped .form-group:nth-of-type(odd) {
    background-color: var(--hs-blue-20);
}

.name-value-list .form-label {
    font-weight: bold;
    text-align: left;
    padding: 0 .5em 0 .5em;
}

.name-value-list .form-control-plaintext {
    padding: 0 0 0 .5em;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    line-height: 1.2em;
}

.name-value-list.small .form-label {
    font-size: 1rem;
    width: 30%;
}

.name-value-list.small .form-control-plaintext {
    font-size: 1rem;
    width: 70%;
}
.name-value-list.medium .form-label {
    font-size: 1.25rem;
    width: 35%;
}

.name-value-list.medium .form-control-plaintext {
    font-size: 1.25rem;
    width: 65%;
}

.name-value-list.large .form-label {
    font-size: 1.5rem;
    width: 40%;
}

.name-value-list.large .form-control-plaintext {
    font-size: 1.5rem;
    width: 60%;
}