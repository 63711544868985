.role {
    color: white;
    font-family: janeway, sans-serif;
    font-size: 3vw;
}

.active-role {
    color: white;
    font-family: janeway, sans-serif;
    font-size: 3vw;
    font-weight: bolder;
}

.inactive-role {
    color: white;
    font-family: janeway, sans-serif;
    font-size: 2vw;
    font-weight: lighter;
}