


.swapEmployeeName {
    color: var(--color-text);
    font-size: 1.4rem;
}


.statusLabel{
    border-radius: 0.9rem;
    font-size: 1rem;
    padding: 0.15rem 0.5rem 0.15rem 0.5rem;
}

.initiatorLabel{
    border: 0.1rem solid var(--color-swap-status-expired);
    background-color: var(--color-swap-status-expired);
    color: var(--color-swap-status-expired-text);
}

.recipientLabel{
    border: 0.1rem solid var(--hs-blue-20);
    background-color: var(--hs-blue-20);
    color: var(--color-swap-status-accepted);
}

.accepted{
    border: 0.1rem solid var(--color-swap-status-accepted);
    background-color: var(--color-swap-status-accepted);
    color: var(--color-button-text);
}

.approved{
    border: 0.1rem solid var(--color-swap-status-approved);
    background-color: var(--color-swap-status-approved);
    color: var(--color-button-text);
}


.pending{
    border: 0.1rem solid var(--color-swap-status-pending);
    background-color: var(--color-swap-status-pending);
    color: var(--color-button-text);
}

.proposed{
    border: 0.1rem solid var(--color-swap-status-proposed);
    background-color: var(--color-swap-status-proposed);
    color: var(--color-button-text);
}

.denied{
    border: 0.1rem solid var(--color-swap-status-denied);
    background-color: var(--color-swap-status-denied);
    color: var(--color-button-text);
}
.declined{
    border: 0.1rem solid var(--color-swap-status-declined);
    background-color: var(--color-swap-status-declined);
    color: var(--color-button-text);
}

.notAccepted{
    border: 0.1rem solid var(--color-swap-status-notAccepted);
    background-color: var(--color-swap-status-notAccepted);
    color: var(--color-button-text);
}


.expired{
    border: 0.1rem solid var(--color-swap-status-expired);
    background-color: var(--color-swap-status-expired);
    color: var(--color-swap-status-expired-text);
}


.retracted{
    border: 0.1rem solid var(--color-swap-status-retracted);
    background-color: var(--color-swap-status-retracted);
    color: var(--color-button-text);
}




.spaceBetween {
    display: flex;
    justify-content:space-between;
}

.marginTen{
    margin: 0.9rem;
}

.marginBottomTen{
    margin-bottom: 0.9rem;
}



.notes{
    padding-left: 0.9rem;
    font-size: 1.2rem;
}

.bigLabel {
    color: var(--color-text);
    font-size: 1.4rem;
    font-weight: 700;
    padding-top: 0.25rem;
}


.includeNotes{
    margin-top: 0.5rem;
    margin-left: 0.9rem;
}



.disabledIncludeNotesSpan{
    margin-left: 1.8rem;
    margin-top: 0.6rem;
    color: var(--color-swap-status-expired-text);
    font-size: 1rem;
    font-weight: 700;
}

.includeNotesSpan{
    margin-left: 1.8rem;
    margin-top: 0.6rem;
    color: var(--color-text);
    font-size: 1rem;
    font-weight: 700;
}



.displayNotes{
    margin-top: 0.5rem;
    margin-right: 0.4rem;
    font-size: 1rem;
    font-weight: 700;
}

.swapCardHeader{
    background-color: var(--hs-blue-40);
    padding: 0.9rem;
}

.swapCard{
    border-radius: 0.9rem;
    border: 0.1rem solid transparent;
}

.displayNoteCardHeader{
    background-color: transparent;
    padding: 0.9rem;
    border: none;
}

.displayNoteCard{
    border: none;
}

.potentialAssignmentsCardBody{
    padding: 0.9rem;
}


.swapToggleButton {
    border: none;
    padding: 0;
    background: none;
    width: 100%;
    font-size: 1.6rem;
}


.underline{
    text-decoration: underline;
}


.retractMsg {
    font-size: 1.2rem;
    font-weight: 700;
}


/****************** Start of tooltip styling   *******************/
.hsTooltip  {
    background-color: var(--hs-blue-60);
    color: var(--color-button-text);
    height: auto;
}

.hsTooltip  .popover-body {
    padding: 0.5rem 0.75rem;
    color: var(--color-button-text);
    font-weight: 700;
    font-size: 1.2rem;
}


.hsTooltip  .arrow::after {
    border-bottom-color: var(--hs-blue-60);
    border-top-color: var(--hs-blue-60);
}
/****************** Start of tooltip styling   *******************/