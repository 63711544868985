#assignments {
    font-size: 1.5rem;
}

#assignments th {
    background-color: var(--hs-blue-20);
}

#assignments td.fc-list-event-time, #assignments td.fc-list-event-graphic {
    display: none;
}
#assignments td.fc-list-event-title {
    white-space: pre-wrap;
}
#assignments td.fc-list-event-title a:empty::before {
    content: "No Assignments";
}