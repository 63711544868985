
#install {
    font-size: 1rem;
}

#install .browserInfo {
    display: none;
}

#install .pwaPromptShareIcon {
    flex: 0 0 auto;
    height: 30px;
    left-margin: 0px;
    margin-right: 0px;
    width: 25px;
}

#install .title {
    text-decoration: underline;
}

#install .content {
    text-align: center;
    margin: 1rem;
}

ul, ol, .block {
    margin: .5rem .5rem 0 .8rem;
    padding: .5rem;
    display: inline-block;
    text-align: left;
}

#install .controls {
    margin-top: 1rem;
}

#install p {
    text-align: left;
    margin: 1rem 0 0 0;
}

#install button.close {
    color: black;
    text-align: right;
    font-size: 3rem;
    margin: 0 .5rem;
    line-height: .6;
}