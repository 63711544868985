
.swapGeneralInfo {
    color: var(--color-text);
    font-size: 1.2rem;
    font-weight: normal;
}

.boldFont {
    font-weight: 700;
}

.italicFont {
    font-style: italic;
}

.smallFont{
    font-size: 0.9rem;
}

.mediumFont{
    font-size: 1rem;
}

.swapProposal{
    border-radius: 0.9rem;
    border: 0.1rem solid var(--hs-blue-80);
    margin: 0.9rem 0.9rem 2.7rem 0.9rem;
    color:  var(--color-text);
    width: 95%;
}

.paddingTen{
    padding: 0.9rem;
}


.swapPartner{
    border-radius: 0.4rem;
    font-size: 1.2rem;
    padding: 0.9rem;
}


.lightBlueBg{
    background-color:var(--hs-blue-20);
    color:  var(--color-text);
}

.darkblueBg{
    background-color: var(--hs-blue-80);
    color: var(--color-button-text);
}

.swapWarning{
    border-radius: 0.25rem;
    background-color: var(--hs-yellow);
    color: var(--color-text);
    font-size: 1rem;
    padding: 0.4rem;
}



.flexAlignCenter {
    align-items: center;
}

.swapPartnerTable{
    table-layout:fixed;
    width: 100%;
}

.dateCol {
    width: 45%;
}
