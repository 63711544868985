#launch {
    background-color: var(--hs-blue-100);
    color: white;
    height: 100vh;
}

#launch #banner {
    width: 100%;
    padding: 2rem;
}
#launch #banner .version {
    padding: 2rem;
}

#launch .btn.btn-primary {
    margin: 2rem;
    color: var(--color-text);
    background-color: white;
    border: .2rem solid var(--hs-blue-80);
}

#launch a {
    color: white;
    text-decoration: underline;
}

