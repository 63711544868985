#swapRecipientsDiv {
    height: 40vh;
    width: 100%;
    overflow-y: scroll;
    -webkit-mask-image: linear-gradient(180deg, var(--color-text) 80%, transparent);
    mask-image: linear-gradient(180deg, var(--color-text) 80%, transparent);
    padding-bottom: 2rem;
}

#submitSwapProposalDiv {
    height: 30vh;
    width: 100%;
    overflow-y: scroll;
    -webkit-mask-image: linear-gradient(180deg, var(--color-text) 80%, transparent);
    mask-image: linear-gradient(180deg, var(--color-text) 80%, transparent);
    padding-bottom: 2rem;
}

.swapLeftArrow {
    position: absolute;
    left: 0;
    padding-left: 0.9rem;
    color: var(--color-header-text)
}


.swapModalHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-header);
    color: var(--color-header-text);
}



.swapModalHeader .close {
    position: absolute;
    right: 0.9rem;
    color: var(--color-header-text);
}

.swapModalBody {
    text-align: left !important;
}

.swapNote {
    width: 100%;
    margin: 0.9rem 0.4rem 0.9rem 0.4rem;
    color: var(--color-text);
}


.swapShift{
    border-radius: 0.9rem;
    border:  0.1rem solid var(--hs-blue-20);
    padding: 0.4rem;
    margin: 0.9rem 0.4rem 0.9rem 0.4rem;
    color: var(--color-text);
    font-size:  1.4rem;
    width: 98%;
}

.initSwapLabel {
    border: 0;
    margin: 0.4rem 0.25rem 0.4rem 0.25rem;
    color:var(--color-text);
    font-size: 1.4rem;
    width: 98%;
}


.swapCheck {
    flex: 0 0 15%;
}



.swapInitShift {
    padding: 0;
    margin: 0;
    color: var(--color-header-text);
    font-size: 1.4rem;
    display: flex;
    flex-direction: row;
    width: 98%;
}


.swapLeftLabel {
    flex: 0 0 15%;
    border-radius: 0.9rem 0 0 0.9rem;
    background-color: var(--hs-blue-100);
    padding: 0.4rem 1.4rem 0.9rem 0.9rem;
    clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
}


.swapInitDetail {
    flex: 0 0 85%;
    display: flex;
    align-items: center;
    border-radius: 0 0.9rem 0.9rem 0;
    font-size: 1.4rem;
    margin-left: -1.5rem;
    background-color: var(--hs-blue-80);
    padding: 0.9rem 0.4rem 0.9rem 1.4rem;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 5% 50%);
}




.swapInitEmployee {
    font-size: 1em;
}



.swapDetail {
    color:  var(--color-text);
    flex: 0 0 85%;
    font-size: 1.4rem;
}




.swapEmployee {
    font-size: 1rem;
    margin-top: 0.6rem;
}



.swapAreaDiv {
    margin: 0 0.25rem 0.9rem 0.25rem;
}


.swapAreaSelect{
    display: block;
    width: 100%;
    height: 3rem;
    padding: 0.4rem 0.75rem;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.4rem;
    color: var(--color-text);
    background-color: var(--color-background);
    background-clip: padding-box;
    border: 0.1rem solid var(--hs-blue-20);
    border-radius: 0.25rem;
}

.numberCircle {
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    border-radius: 50%;
    background: var(--color-background);
    border: 0.1rem solid var(--color-background);
    color: var(--hs-blue-80);
    text-align: center;
    margin-left: 0.25rem;
}



.italicOption {
    font-style: italic;
    font-size: 1.2rem;
    font-weight: normal;
}

.underline {
    text-decoration: underline;
}


.swapLeftArrow .fa.fa-chevron-left {
    color: var(--color-background);
    font-size: 1rem;
    font-weight: bold;
}

.swapReviewNote{
    min-height: 7rem;
    width: 98% !important;
}

/****************** Start of checkbox styling   *******************/
.bigCheckbox {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.bigCheckbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 3rem;
    width: 3rem;
    border-radius: 0.25rem;
    border: 0.1rem solid var(--hs-blue-100);
    background-color: var(--color-background);
}



/* When the checkbox is checked, add a blue background */
.bigCheckbox input:checked ~ .checkmark {
    background-color: var(--hs-blue-100);
    border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.bigCheckbox input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.bigCheckbox .checkmark:after {
    left: 1.1rem;
    top: 0.6rem;
    width: 0.7rem;
    height: 1.4rem;
    border: solid var(--color-background);
    border-width: 0 0.3rem 0.3rem 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/****************** End of Circle checkbox styling   *******************/
