:root {
    --fc-button-text-color: var(--color-text);
    --fc-button-bg-color: var(--color-button-bg);
    --fc-button-border-color: var(--color-button-border);
    --fc-button-hover-bg-color: var(--color-button-hover-bg);
    --fc-button-hover-border-color: var(--color-button-hover-border);
    --fc-button-active-bg-color: var(--color-button-active-bg);
    --fc-button-active-border-color: var(--color-button-active-border);
    --fc-bg-event-opacity: 1;
    --fc-today-bg-color: transparent;
    --focus-color: var(--hs-blue-40);
    --today-color-bg: var(--hs-blue-80);
    --today-color-text: white;
}

#calendarMonth {
    font-size: 1rem;
}

.fc-theme-bootstrap a:not([href]) {
    color: inherit;
    text-decoration: none;
}

.fc .fc-toolbar.fc-header-toolbar {
    padding: .5rem 1rem .5rem 1rem;
    margin: 0;
}

.fc .fc-toolbar.fc-footer-toolbar {
    margin-top: 0;
    margin-bottom: 0;
}

.fc .fc-toolbar-title {
    color: var(--app-title);
    font-size: 1.5rem;
    font-weight: bold;
    align-self: center;
}

.fc .fc-toolbar.fc-header-toolbar button {
    margin: 0;
}

button.fc-prev-button.btn.btn-primary {
    margin: 0 .5rem 0 1rem;
}

.fc-bg-event {
    background: white;
}

button.fc-prev-button.btn.btn-primary, button.fc-next-button.btn.btn-primary {
    color: var(--fc-button-bg-color);
    background-color: transparent;
    border: 0.1rem solid var(--fc-button-bg-color);
    font-size: 1rem;
}

button.fc-today-button[disabled], button.fc-prev-button[disabled], button.fc-next-button[disabled] {
    opacity: 0.3;
    cursor: not-allowed;
    pointer-events: none;
}

.fa.fa-chevron-left, .fa.fa-chevron-right {
    color: var(--hs-blue-80);
    font-size: 1rem;
    font-weight: bold;
}

.monthViewEvent {
    font-size: .7rem;
}

/* Overriding FC css that sets background of day with assignments to green */

.fc .fc-bg-event {
    opacity: 1.0;
}

.fc-bg-event.publishStatus {
    width: 100%;
    height: 10%;
}

.fc-bg-event.publishStatus.not-published {
    background-color: var(--color-period-not-published);
}

.fc-bg-event.publishStatus.balancing {
    background-color: var(--color-period-balancing);
}

.fc-bg-event.publishStatus.published {
    background-color: var(--color-period-published);
}

.fc-bg-event.publishStatus.archived {
    background-color: var(--color-period-archived);
}

.selected-date {
    background-color: var(--focus-color) !important;
    color: white !important;
}

.fc .fc-bg-event.selected-date {
    background-color: var(--focus-color);
}

/* Requests timeOff, work and available shifts */

.fc-daygrid-day-top {
    justify-content: flex-start;
    margin: 16% 4% 0 0;
}

.fc-daygrid-day-events {
    margin: 0 1rem 0 1rem;
}

.fc-daygrid-event-harness {
    flex-direction: column;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.fc-daygrid-event {
    background-position: right bottom;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    border-width: 0;
    margin: 0;
    opacity: var(--fc-bg-event-opacity, 1.0);
    width: 100%;
}

.fc-event-title {
    opacity: var(--fc-bg-event-opacity, 0.0);
}

.fc-daygrid-event::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}

.fc-daygrid-event.assignments::before {
    color: var(--hs-blue-80);
    position: relative;
    float: left;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f073";
}

.fc-daygrid-event.availableShifts::before {
    color: var(--hs-blue-80);
    position: relative;
    float: right;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f005";
}

.fc-daygrid-event.work::before {
    color: var(--hs-blue-100);
    position: relative;
    float: right;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f017";
}

.fc-daygrid-event.timeoff::before {
    color: orangered;
    position: relative;
    float: right;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f185";
}

.fc-daygrid-event.day-homeAssignment {
    background-image: url(./images/fontawesome/user-solid-blue.svg);
    background-position: bottom left;
}

.fc-daygrid-event.day-past-homeAssignment {
    background-image: url(./images/fontawesome/user-solid-blue.svg);
    background-position: bottom left;
}

.fc-daygrid-event.day-floatAssignment {
    background-image: url(./images/fontawesome/user-solid-blue.svg);
    background-position: bottom left;
}

.fc-daygrid-event.day-past-floatAssignment {
    background-image: url(./images/fontawesome/user-solid-blue.svg);
    background-position: bottom left;
}

.fc-daygrid-event.day-timeOff {
    background-image: url(./images/fontawesome/sun-solid-orange.svg);
    background-position: bottom right;
}

.fc-daygrid-event.day-timeOff-assignment {
    background-image: url(./images/fontawesome/calendar-alt-solid-blue.svg), url(./images/fontawesome/sun-solid-orange.svg);
    background-position: bottom left, bottom right;
}

.fc-daygrid-event.day-timeOff-homeAssignment {
    background-image: url(./images/fontawesome/user-solid-blue.svg), url(./images/fontawesome/sun-solid-orange.svg);
    background-position: bottom left, bottom right;
}

.fc-daygrid-event.day-timeOff-floatAssignment {
    background-image: url(./images/fontawesome/user-solid-blue.svg), url(./images/fontawesome/sun-solid-orange.svg);
    background-position: bottom left, bottom right;
}

.fc-daygrid-event.day-work {
    background-image: url(./images/fontawesome/clock-solid-orange.svg);
    background-position: bottom right;
}

.fc-daygrid-event.day-work-assignment {
    background-image: url(./images/fontawesome/calendar-alt-solid-blue.svg), url(./images/fontawesome/clock-solid-orange.svg);
    background-position: bottom left, bottom right;
}

.fc-daygrid-event.day-work-homeAssignment {
    background-image: url(./images/fontawesome/user-solid-blue.svg), url(./images/fontawesome/clock-solid-orange.svg);
    background-position: bottom left, bottom right;
}

.fc-daygrid-event.day-work-floatAssignment {
    background-image: url(./images/fontawesome/user-solid-blue.svg), url(./images/fontawesome/clock-solid-orange.svg);
    background-position: bottom left, bottom right;
}

.fc-daygrid-event.day-availableShifts {
    background-image: url(./images/fontawesome/star-solid-orange.svg);
    background-position: bottom right;
}

.fc-daygrid-event.day-availableShifts-assignment {
    background-image: url(./images/fontawesome/calendar-alt-solid-blue.svg), url(./images/fontawesome/star-solid-orange.svg);
    background-position: bottom left, bottom right;
}

.fc-daygrid-event.day-availableShifts-homeAssignment {
    background-image: url(./images/fontawesome/user-solid-blue.svg), url(./images/fontawesome/star-solid-orange.svg);
    background-position: bottom left, bottom right;
}

.fc-daygrid-event.day-availableShifts-floatAssignment {
    background-image: url(./images/fontawesome/user-solid-blue.svg), url(./images/fontawesome/star-solid-orange.svg);
    background-position: bottom left, bottom right;
}

@media screen and (max-width: 767px) {
    .fc .fc-col-header-cell-cushion {
        font-size: 1rem;
    }

    .fc-daygrid-day-top {
        margin: 8% 0 0 0;
    }

    .fc .fc-daygrid-day-number {
        font-size: 1.5rem;
        line-height: 1;
        margin: 0.1rem 0.1rem 0 0;
        padding: .3rem .3rem .3rem .3rem;
    }

    .fc .fc-day-today .fc-daygrid-day-number {
        color: var(--today-color-text);
        background-color: var(--today-color-bg);
        font-weight: bolder;
        border: 0;
        border-radius: 50%;
    }

    .fc-daygrid-day-events {
        height: 40%;
        margin: 0 0.3rem 0 .7rem;
    }

    .fc-daygrid-event {
        height: 1rem;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .fc .fc-col-header-cell-cushion {
        font-size: 1.3rem;
    }

    .fc .fc-daygrid-day-number {
        font-size: 1.3rem;
    }

    .fc-daygrid-day-events {
        height: 50%;
    }

    .fc-daygrid-event {
        height: 1.5rem;
    }
}

@media screen and (min-width: 1024px) {
    .fc .fc-col-header-cell-cushion {
        font-size: 1.5rem;
    }

    .fc .fc-daygrid-day-number {
        font-size: 1.5rem;
    }

    .fc-daygrid-day-events {
        height: 60%;
    }

    .fc-daygrid-event {
        height: 1.5rem;
    }
}

.fc-list-event-dot {
    display: none !important;
}

.fc table {
    border: 0;
    border-top: 0.1rem solid var(--hs-blue-40);
}

.fc th {
    border: 0.1rem solid var(--hs-blue-40);
}

.fc td {
    border: 0.1rem solid var(--hs-blue-40);
}