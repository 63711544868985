.user, .profile {
    font-size: 3vw;
    line-height: normal;
}

.profile-link {
    color: white;
    text-decoration: underline;
}

.user-skill-dcpos {
    white-space: nowrap;
}
