.marginTop10 {
    margin-top: 0.9rem;
}


.swapLabel {
    color: var(--color-text);
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0.9rem 0 0 0;
}

hr.swapSeparator {
    border-top: 0.1rem solid var(--hs-blue-20);
}




.swapButton{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 0.4rem;
}

.swapRecipientNote{
    min-height: 7rem;
    width: 100%;
}

