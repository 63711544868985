#calendarDay th, #calendarDay td {
    padding: 0;
    margin: 0;
}

#dayBanner {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: .5rem 1rem .5rem 1rem;
    font-size: 1.5rem;
    font-weight: bold;
}

#dayBanner .title {
    width: 50%;
}

#dayBanner .controls {
    text-align: right;
    width: 50%;
    wrap-option: nowrap;
    font-size: 1.1rem;
}

#dayBanner .controls .request.disabled {
    opacity: .5;
    pointer-events: none
}

#dayBanner .controls .request:active {
    color: var(--hs-blue-20);
}

#calendarDay .assignment {
    font-size: 1.5rem;
    font-weight: normal;
    padding: .5rem 0 .5rem 1rem;
}