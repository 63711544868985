#legalNotice {
    margin: 2px
}

#privacypolicy {
    text-decoration: underline;
}

#privacypolicy i {
    text-decoration: none;
}