
.areaNotOpen {
    color: red;
}

.areaOpen {
    color: var(--color-text);
}

.form-control.areaSelect.readonly {
    appearance: none;
    pointer-events: none;
}