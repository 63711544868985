#assignment .modal-title {
    font-size: 2rem;
    line-height: 1;
}

.homeAssignment {
    content: url(components/Calendar/images/fontawesome/user-solid-blue.svg);
    width: 10%;
    background-color: var(--color-background);
    border: .1rem solid var(--color-background);
    border-radius: 2rem;
}

.floatAssignment {
    content: url(components/Calendar/images/fontawesome/user-solid-blue.svg);
    width: 10%;
    background-color: var(--color-background);
    border: .1rem solid var(--color-background);
    border-radius: 2rem;
}

.assignmentSubtitle {
    font-size: 1rem;
}

#assignment .form-label, #assignment .form-control-plaintext, #assignment .btn {
    font-size: 1.25rem;
}

#assignment .form-control-plaintext.archived {
    background-color: var(--color-period-archived);
}

#assignment .form-control-plaintext.published {
    background-color: var(--color-period-published);
}

#assignment .form-control-plaintext.balancing {
    background-color: var(--color-period-balancing);
}

#assignment .form-control-plaintext.not-published {
    background-color: var(--color-period-not-published);
}


