
#areaSchedule {
    border: 0;
    margin: 0;
    padding: 0;
    width: 100vw;
    height: calc(97% - var(--height-header) - (var(--height-selector) * 2) - var(--height-footer));
    position: fixed;
    top: calc(var(--height-header) + (var(--height-selector) * 2));
}

#areaSchedule .rowHeader .name {
    white-space: pre-wrap;
    overflow-wrap: break-word;
    line-height: 1em;
}

#areaSchedule .rowHeader .arrow {
    display: flex;
    float: right;
}

#areaSchedule .tr.counts .rowHeader, #areaSchedule .tr.hours .rowHeader {
    text-align: right;
}

#areaSchedule .th.weekend, #areaSchedule .td.weekend {
    background-color: var(--hs-blue-20);
}

#areaSchedule .tr.skill {
    border-bottom: 0;
}

#areaSchedule .tr.skill .td {
    background-color: var(--hs-blue-80);
    border-color: var(--hs-blue-80);
    color: white;
}

#areaSchedule .td.day {
    padding: .25rem;
}

#areaSchedule .th > div, #areaSchedule .td > div {
    padding: 0;
}

#areaSchedule .assignment {
    font-size: 1rem;
    text-align: left;
    word-wrap: break-word;
    white-space: normal;
}

#areaSchedule div.assignment + div.assignment {
    margin-top: .6rem;
}

#areaSchedule .assignment .row1, #areaSchedule .assignment .row2 {
    line-height: 1;
}

/*#areaSchedule .assignment .row1.float {*/
/*    font-size: .8rem;*/
/*}*/

/*#areaSchedule .assignment .row2 {*/
/*    font-size: .9rem;*/
/*    line-height: .9;*/
/*}*/

#employeeContact, #employeeContact .btn {
    font-size: 1.5rem;
}
