
#switchProfile .modal-body {
    text-align: center;
}

#switchProfile button.btn {
    width: 75%;
    padding: 1em;
    margin: 1em;
    color: var(--color-xlarge-button-text);
    background-color: var(--color-xlarge-button-bg);
    border-color: var(--color-xlarge-button-border);
}

#switchProfile button.btn .profile {
    font-size: 1.5rem;
    text-align: left;
    font-weight: bolder;
    /*white-space: nowrap;*/
}

#switchProfile .profile .profile-name {
    margin-left: .5em;
    overflow-wrap: break-word;
}
.switchWarningSign{
    font-size: 1.0rem;
    vertical-align: top;
}


.switchProfileWarning{
    border-radius: 0.25rem;
    background-color: var(--hs-yellow);
    color: var(--color-text);
    font-size: 1.0rem;
    padding: 0.4rem;
}

.boldFont {
    font-weight: 700;
}
