:root {
    /*Used for page headings, including pop-ups, call to action buttons, selected check boxes,*/
    /*Also used for text on white backgrounds and 20% and 40% backgrounds.*/
    /*Text on this background color should be white.*/
    --hs-blue-100: #0F2A51;

    /*Used for major headings where appropriate, including the right side of �??Shift to Swap�?? on the Swaps pages, the Accordions on My Profile, and the 4th level of the Area Schedule screen (Area Schedule is an outlier).*/
    /*Text on this background color should be white.*/
    --hs-blue-80: #3D71A0;

    /*Currently not used.  Text, whether dark or white, does not display well on this color.*/
    --hs-blue-60: #6D95B8;

    /*Used for the Day/Date near top of Area schedule.  Also used on the Swap Status screen for the Potential Assignments (where the up and down arrows are).  Can also be used where a 3rd level heading is needed.*/
    /*Text on this background color should be #0F2A51.*/
    --hs-blue-40: #9EB8CF;

    /*Used where lots of color is needed that shouldn�??t be overwhelming and for fourth level heading*/
    /*Text on this background color should be #0F2A51.*/
    --hs-blue-20: #CEDCE7;

    /*Comes from the orange arrow in our icon*/
    --hs-orange: #EB4E31;

    /*Currently used as background color for warning messages in swaps*/
    --hs-yellow: #E7A936;

    --hs-primary-stream-blue: #0B75E0;
    --hs-primary-bright-blue: #00B2FF;

    --color-header: var(--hs-blue-100);
    --color-header-text: white;
    --color-footer: var(--hs-blue-100);
    --color-header-text: white;
    --color-text: var(--hs-blue-100);
    --color-background: white;
    --color-button-text: white;
    --color-button-disabled-text: var(--hs-blue-20);
    --color-button-bg: var(--hs-blue-100);
    --color-button-border: var(--hs-blue-100);
    --color-button-hover-bg: var(--hs-blue-80);
    --color-button-hover-border: var(--hs-blue-80);
    --color-button-active-bg: var(--hs-blue-100);
    --color-button-active-border: var(--hs-blue-40);
    --color-buttom-disabled-opacity: 0.5;

    --color-xlarge-button-text: var(--hs-blue-100);
    --color-xlarge-button-bg: var(--hs-blue-20);
    --color-xlarge-button-border: var(--hs-blue-100);

    --color-navlink: var(--hs-blue-100);
    --color-navlink-active: var(--hs-blue-40);
    --color-navbar: var(--hs-blue-20);

    --color-warning-bg: var(--hs-orange);
    --color-warning-text: white;

    --app-title: var(--hs-blue-100);
    --color-divider: var(--hs-blue-100);


    --color-swap-status-accepted: #007bff;
    --color-swap-status-approved: #00BA7C;
    --color-swap-status-pending: #FDCA00;
    --color-swap-status-proposed: #234B85;
    --color-swap-status-denied: #FF0000;
    --color-swap-status-declined: #fd5800;
    --color-swap-status-notAccepted: #FE9881;
    --color-swap-status-expired: #F1F1F1;
    --color-swap-status-retracted: #6C4EF3;
    --color-swap-status-expired-text: #737373;

    --color-period-published: lightblue;
    --color-period-balancing: lightgray;
    --color-period-not-published: #F9E7B2;
    --color-period-archived: lightblue;

    --vh: 1vh;
}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 1, 2021 */
@font-face {
    font-family: 'janeway';
    src: url('./fonts/janeway/Janeway-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'janeway';
    src: url('./fonts/janeway/Janeway-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'janeway';
    src: url('./fonts/janeway/Janeway-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'janeway';
    src: url('./fonts/janeway/Janeway-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'janeway';
    src: url('./fonts/janeway/Janeway-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-size: medium;
}

html, body {
    font-size: 3vw;
    font-family: 'janeway', sans-serif !important;
    color: var(--color-text);
    background-color: var(--color-background);
}

html, body, body > div {
    /* the react root */
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

.feature {
    font-size: 1.6rem;
    font-weight: normal;
}

.feature-icon {
    vertical-align: text-before-edge;
}

.userinfo {
    font-size: 1rem;
    font-weight: normal;
}

.headline {
    text-align: center;
    font-weight: normal;
    font-size: 1rem;
}

.headline.offline {
    background-color: var(--color-warning-bg);
    color: var(--color-warning-text);
}

.error {
    color: red;
    margin-top: -0.5rem;
    margin-bottom: 0.5rem;
}

.disabled {
    pointer-events: none;
    opacity: 0.4;
}

.title {
    font-size: 1.5rem;
    color: var(--app-title)
}

.btn {
    border-top-left-radius: 10rem !important;
    border-bottom-left-radius: 10rem !important;
    border-top-right-radius: 10rem !important;
    border-bottom-right-radius: 10rem !important;
    display: inline-block;
    padding: 0.3rem 0.75rem;
    margin: 0.3rem 0.3rem 0.3rem 0.3rem;
    box-sizing: border-box;
    text-decoration: none;
    font-weight: bold;
    color: white;
    background-color: var(--hs-blue-100);
    border: 0.1rem solid white;
    text-align: center;
    transition: all 0.2s;
}

.btn.btn-primary {
    color: white;
    font-weight: normal;
    background-color: var(--hs-blue-100);
    border: 0.1rem solid white;
}

.btn.btn-secondary {
    color: var(--hs-blue-100);
    font-weight: bold;
    background-color: white;
    border: 0.2rem solid var(--hs-blue-100);
}

.btn.btn-secondary.disabled, .btn.btn-secondary:disabled {
    color: lightgrey;
    opacity: 1;
    font-weight: bold;
    background-color: white;
    border: 0.2rem solid lightgrey;
}

.modal-header {
    background-color: var(--hs-blue-100);
    color: white;
    border: 0;
}

button.close, button.close:hover {
    color: white;
    text-shadow: gray;
    opacity: 1;
}

.Hcenter {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    padding: .1rem .1rem .1rem .1rem;
}

.profilePrimary,
button.btn.btn-profilePrimary {
    background-color: var(--hs-blue-40);
    color: white;
}

.profileOther,
button.btn.btn-profileOther {
    background-color: var(--hs-blue-40);
    color: white;
}

/*Button br {*/
/*    display: block;*/
/*    margin: 0.1rem 0;*/
/*    height: 0.1rem;*/
/*    font-size: 0.1rem;*/
/*    line-height: 1px;*/
/*}*/

.tapToContinue {
    color: var(--hs-blue-20);
}

.version {
    font-size: 1rem;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: var(--hs-blue-20) !important;
}

#availableShiftsView .areaSelect {
    width: 100%;
    font-size: 1.5rem;
}

#legalNotice {
    padding: 1rem;
}

#bulletin, #notice .modal-content {
    width: 90%;
    position: absolute;
    left: 5%;
    top: 40%;
    text-align: center;
    padding: 1rem;
    color: var(--color-text);
    font-size: 1.5rem;
    font-weight: bold;
    background-color: white;
    border: 0.1rem solid var(--hs-blue-100);
    opacity: 1 !important;
}

#splashView, #serviceStatus, #errorView {
    background-color: var(--hs-blue-100);
    color: white;
    height: 100vh;
}

#splashView #banner, #serviceStatus #banner, #errorView #banner {
    width: 100%;
    padding: 2rem;
}

#splashView #banner .version, #serviceStatus #banner .version {
    padding: 2rem;
}

#launchOptions {
    padding-top: 5rem;
}
/****************** Start of Landscape orientation styling   *******************/

#landscapeView {
    display: none;
    background-color: var(--color-header);
    color: white;
    width: 100vw;
    height: 100vh;
}

#landscapeView .banner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

#landscapeView .banner img.ansos2go {
    width: 80%;
    height: 50%;
}

@media screen and (orientation: landscape) and (min-width: 500px) {
    #layout {
        display: none;
    }

    #install {
        display: none;
    }

    #landscapeView {
        display: block;
    }
}

/****************** Start of HealthStream Custom checkbox styling   *******************/
/* HS checkbox would be filled with HS dark blue. Its size should be bigger than default.   */
/* HS checkboxes are currently used in "Swap Status" screen & "User Profile" screen         */
.hsCheckbox {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.hsCheckbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.hsCheckmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 0.1rem;
    border: 0.1rem solid var(--hs-blue-100);
    background-color: var(--color-background);
}

/* When the checkbox is disabled */
.hsCheckbox input:disabled ~ .hsCheckmark {
    border: 0.1rem solid var(--color-swap-status-expired-text);
    opacity: 0.7;
}

/* When the checkbox is checked, add a HS dark blue background */
.hsCheckbox input:checked ~ .hsCheckmark {
    background-color: var(--hs-blue-100);
    border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.hsCheckmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.hsCheckbox input:checked ~ .hsCheckmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.hsCheckbox .hsCheckmark:after {
    left: 0.5rem;
    top: 0.25rem;
    width: 0.4rem;
    height: 0.75rem;;
    border: solid var(--color-background);
    border-width: 0 0.2rem 0.2rem 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.flexDisplay {
    display: flex;
    flex-direction: row;
}

/****************** End of HealthStream Custom checkbox styling   *******************/

/****************** Start of HealthStream Custom radio button styling   *******************/

.hsRadio {
    display: block;
    position: relative;
    margin-bottom: 0.1rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.hsRadio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.radioCheckMark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    border: 0.1rem solid var(--hs-blue-100);
    background-color: var(--color-background);
}


/* When the radio button is checked, add a blue background */
.hsRadio input:checked ~ .radioCheckMark {
    background-color: var(--hs-blue-100);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radioCheckMark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.hsRadio input:checked ~ .radioCheckMark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.hsRadio .radioCheckMark:after {
    left: 0.4rem;
    top: 0.4rem;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background: white;
}


/****************** End of HealthStream Custom radio button styling     *******************/