.notification {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
}

.notificationDetail {
    width: 100%;
    margin: 0;
    color: var(--color-text);
    font-size: 1.25rem;
}


.notification.list-group-item,
.notification.list-group-item.disabled,
.notification.list-group-item-action {
    color: var(--color-text);
}

.notificationDetail .title {
    background-color: var(--hs-blue-20);
    width: 100%;
}

.notification .arrow .fas {
    font-size: 2rem;
}

.notificationAssignments {
    padding-left: 0.5rem;
    font-size: 1.25rem;
    color: var(--color-text);
}

.boldFont {
    font-weight: 700;
}

#notificationsView .form-control-plaintext,  .notificationDetail .form-control-plaintext, .noteLabel{
    color: var(--color-text);
}

